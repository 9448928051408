export const RESET_STATE = "RESET_STATE";
export const NETWORK_ERROR = "NETWORK_ERROR";

export const SET_SNACKBAR = "SET_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const ADMIN_AUTH_SET_IS_LOGGED_IN = "ADMIN_AUTH_SET_IS_LOGGED_IN";

export const INTRO_SET = "INTRO_SET";
export const INTRO_SET_DIALOG_COLOR = "INTRO_SET_DIALOG_COLOR";
export const INTRO_SET_DIALOG_IMAGE = "INTRO_SET_DIALOG_IMAGE";
export const INTRO_SET_COLOR = "INTRO_SET_COLOR";
export const INTRO_SET_IMAGE = "INTRO_SET_IMAGE";
export const INTRO_SET_TEXT = "INTRO_SET_TEXT";

export const PROJECTS_SET = "PROJECTS_SET";

// dialogs
export const PROJECT_SET_COLOR_DIALOG = "PROJECT_SET_COLOR_DIALOG";
export const PROJECT_SET_MEDIA_DIALOG = "PROJECT_SET_MEDIA_DIALOG";
export const PROJECT_SET_EDIT_MEDIA_DIALOG = "PROJECT_SET_EDIT_MEDIA_DIALOG";
export const PROJECT_SET_BUTTON_DIALOG = "PROJECT_SET_BUTTON_DIALOG";
export const PROJECT_SET_BUTTON_DIALOG_BUTTON =
  "PROJECT_SET_BUTTON_DIALOG_BUTTON";
export const PROJECT_SET_SUBMISSION_DIALOG = "PROJECT_SET_SUBMISSION_DIALOG";
// fields
export const PROJECT_SET = "PROJECT_SET";
export const PROJECT_SET_POSITION = "PROJECT_SET_POSITION";
export const PROJECT_SET_COLOR = "PROJECT_SET_COLOR";
export const PROJECT_SET_CATEGORY_TAGS = "PROJECT_SET_CATEGORY_TAGS";
export const PROJECT_SET_FEATURE_TAGS = "PROJECT_SET_FEATURE_TAGS";
export const PROJECT_SET_TITLE = "PROJECT_SET_TITLE";
export const PROJECT_SET_DESCRIPTION_LIST = "PROJECT_SET_DESCRIPTION_LIST";
export const PROJECT_SET_DESCRIPTION_ELEMENT =
  "PROJECT_SET_DESCRIPTION_ELEMENT";
export const PROJECT_SELECT_DESCRIPTION = "PROJECT_SELECT_DESCRIPTION";
export const PROJECT_SET_MEDIA_LIST = "PROJECT_SET_MEDIA_LIST";
export const PROJECT_ADD_MEDIA_ELEMENT = "PROJECT_ADD_MEDIA_ELEMENT";
export const PROJECT_SET_BUTTON = "PROJECT_SET_BUTTON";
